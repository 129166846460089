<template>
  <div class="info-box">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z"
        />
      </svg>
    </div>
    <div class="info__text">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box {
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.19) 0 0 8px 0;
  color: #666666;
  font-weight: 700;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px;
  font-weight: 700;
  border-radius: 6px;
  svg {
    height: 20px;
    fill: #00838d;
    margin-right: 10px;
  }
}
</style>
