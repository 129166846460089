var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-box" }, [
    _c("div", [
      _c(
        "svg",
        {
          attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z"
            }
          })
        ]
      )
    ]),
    _c("div", { staticClass: "info__text" }, [
      _vm._v(" " + _vm._s(_vm.message) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }